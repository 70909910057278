import Swiper, { Autoplay, Navigation } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';

let deliverSwiperWr = document.querySelectorAll('.deliver-swiper-wr');
deliverSwiperWr.forEach((el) => {
  if (el) {
    let swiperEl = el.querySelector('.swiper');
    let nextEl = el.querySelector('.swiper-button-next');
    let prevEl = el.querySelector('.swiper-button-prev');

    let swiper = new Swiper(swiperEl, {
      modules: [Autoplay, Navigation],
      observer: true,
      observeParents: true,
      spaceBetween: 20,
      slidesPerView: 1,
      threshold: 10,
      navigation: {
        nextEl: nextEl,
        prevEl: prevEl,
      },
      breakpoints: {
        475: {
          slidesPerView: 1,
        },
        560: {
          slidesPerView: 2,
        },
        768: {
          slidesPerView: 2,
        },
        960: {
          slidesPerView: 3,
        },
        1200: {
          slidesPerView: 3,
        },
        1201: {
          spaceBetween: 0,
          slidesPerView: 3.2,
          autoplay: false,
          enabled: false,
        },
      },
    });

    swiperObserver(swiper);
  }
});
